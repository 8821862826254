import { RootState } from 'stores';

export const getConfigState = ({ config }: RootState) => config;
export const getFeatureFlags = ({ config }: RootState) => config.featureFlags;
export const getInstanceType = ({ config }: RootState) => config.instanceType;
export const getIsDesktop = ({ config }: RootState) => config.instanceType !== 'Central';
export const getPayOnAccountCode = ({ config }: RootState) =>
  config.paymentMethodsConfiguration?.payOnAccountMethodCode;
export const getShowTippingModule = ({ config }: RootState) => config.payment?.v2?.enableTipping ?? true;
export const getPizzaCategoryCode = ({ config }: RootState) => config.intakeSettings?.pizzaCategoryCode ?? 'PIZ';
export const getOtherGroupCode = ({ config }: RootState) => config.intakeSettings?.otherGroupCode ?? 'Oth';
export const getMaxNumberOfDuplicateToppings = ({ config }: RootState) =>
  config.toppingsCustomizationConfiguration?.maximumNumberOfDuplicatesToppings ?? 0;
export const getFiscalConfigState = ({ config }: RootState) => config.fiscalizationConfiguration;
