import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { BasketItem, BasketSummary, ReceiptDiscount, ReceiptProduct } from 'typings/Basket';
import { Coupon, MealCoupon } from 'typings/Coupons';
import { PaymentMethodCode } from 'typings/Payments';

export default interface HybridModeState {
  isHybridModeActive: boolean;
  isOfflineModePanelVisible: boolean;
  isCloudPosAvailable?: boolean; // Used to notify that the connectivity has changed
  lastCloudPosConnectionAttempt?: Date;
  transferredIntake?: TransferredIntake; // Used to restore intake state when switching between modes
  phoneNumberCalling?: string;
  offlineModeEnabled: boolean;
}

export interface CloudPosAvailabilityChangedEvent {
  isAvailable: boolean;
}

export interface HybridModeActivityChangedEvent {
  isActive: boolean;
  offlineModeEnabled: boolean;
}

export interface CloudPosLastConnectionAttemptEvent {
  timestampInMiliseconds: number;
}

export const HybridModeMessages = {
  Hybrid: {
    Events: {
      CloudPosAvailabilityChanged: 'hybrid-communication:cloud-pos-availability-changed',
      HybridModeActivityChanged: 'hybrid-communication:hybrid-mode-activity-changed',
      CloudPosLastConnectionAttemptEvent: 'hybrid-communication:cloud-pos-last-connectionattempt-event',
      BasketRecalculated: 'hybrid-communication:basket-recalculated-event',
      IntakeFinalized: 'hybrid-communication:intake-finalized-event',
      CashierLoggedIn: 'hybrid-communication:cashier-logged-in-event',
      CashierLoggedOut: 'hybrid-communication:cashier-logged-out-event',
      LanguageChanged: 'hybrid-communication:language-changed-event',
      PhoneCalled: 'hybrid-communication:phone-called-event',
      PosRunning: 'hybrid-communication:pos-running-event',
    },
    Commands: {
      SwitchToCloudPos: 'hybrid-communication:switch-to-cloud-pos-cmd',
      SwitchToOfflinePos: 'hybrid-communication:switch-to-offline-pos-cmd',
      RestoreBasket: 'hybrid-communication:restore-basket-cmd',
      OpenCustomerDisplay: 'hybrid-communication:open-customer-display-cmd',
      CloseCustomerDisplay: 'hybrid-communication:close-customer-display-cmd',
      RefreshCustomerDisplay: 'hybrid-communication:refresh-customer-display-cmd',
      ChangeZoomLevel: 'hybrid-communication:change-zoom-level-cmd',
      OpenUrl: 'hybrid-communication:open-url-cmd',
    },
  },
};

type HybridModeSubscriberCallback = (payload: string) => void;

export interface HybridModeCommunicator {
  send(messageName: string, body?: object): void;
  subscribe(messageName: string, callback: HybridModeSubscriberCallback): void;
}

export interface TransferredIntake {
  basketItems: BasketItem[];
  basketCoupons: Coupon[] | MealCoupon[];
  manualDeliveryCharge: number | null;
  remarks?: string;
  manualPriceOverride?: number;
  activeDeliveryType: PickUpTypesValues;
  activePaymentMethod?: PaymentMethodCode;
  storeId: number;
}

export interface BasketRecalculated {
  receiptProducts: ReceiptProduct[];
  receiptDiscounts: ReceiptDiscount[];
  basketSummary?: BasketSummary;
}

export interface LanguageChanged {
  languageCode: string;
}

export interface PhoneCalled {
  phoneNumber: string;
}
