import { createTheme } from '@mui/material/styles';
import loginImage from 'assets/images/login_background.png';
import brandLogo from 'assets/icons/logo.svg';
import typography from './typography';
import { sizing } from './sizing';
import { colors } from './colors';

export const genericS4DThemeColors = {
  primaryMain: colors.green[600],
  primaryLight: colors.green[500],
  primaryDark: '#46B366',
  secondaryMain: '#F0F0F0',
  secondaryLight: '#FFF',
  secondaryDark: '#c8c8c8',
  tertiary: '#fff',
  tertiaryFont: '#737373',
  defaultShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16)',
};

export const BASE_THEME = createTheme({
  brandLogo,
  sizingBig: (val: number) => {
    return `${sizing.bigSpacingBase * val}px`;
  },
  loginImage: {
    url: loginImage,
    backgroundSize: 'cover',
  },
  palette: {
    primary: {
      main: genericS4DThemeColors.primaryMain,
      light: genericS4DThemeColors.primaryLight,
      dark: genericS4DThemeColors.primaryDark,
      contrastText: '#ffffff',
    },
    secondary: {
      main: genericS4DThemeColors.secondaryMain,
      light: genericS4DThemeColors.secondaryLight,
      dark: genericS4DThemeColors.secondaryDark,
      contrastText: '#000000',
    },
    text: {
      primary: '#737373',
      secondary: '#ffffff',
      disabled: '#9E9E9E',
    },
    error: {
      main: '#ff2929',
      dark: '#ad0000',
      light: '#ff9e9e',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ECBE3F',
    },
    customGray: {
      light: '#F4F4F4',
      main: '#E8E8E8',
      dark: '#858585',
      darkHover: '#b5b5b5',
    },
    black: {
      main: '#17191B',
    },
    white: '#FFF',
    virtualReceipt: {
      items: {
        discount: {
          active: {
            icon: 'rgb(255,140,0)',
            background: 'rgb(255,248,220)',
          },
          inactive: {
            icon: colors.red[700],
            background: 'rgb(242,204,204)',
          },
          error: {
            icon: 'rgb(255,41,41)',
            background: '#ff9e9e',
          },
        },
      },
    },
    divider: '#DBE1E3',
    background: { default: colors.grey[50] },
    externalComponentBackground: '#E0E0E0',
    ...colors,
  },
  typography,
  sizing,
  spacing: sizing.spacingBase,
  radius: {
    sm: 4,
    md: 8,
    lg: 12,
    round: '50%',
    pizzaButton: sizing.pizzaButton * 0.55,
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 1024,
      md: 1240,
      lg: 1440,
      xl: 1600,
    },
  },
  mixins: {
    separator: (position) => {
      const boxShadow =
        '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);';
      const border = '1px solid #E8E8E8';

      if (position === 'top') {
        return {
          boxShadow,
          borderTop: border,
        };
      }
      return {
        boxShadow,
        borderBottom: border,
      };
    },
    rotate: {
      rotate: {
        transitionDuration: '0.5s',
        transitionProperty: 'transform',
        transform: 'rotate(0deg)',
      },
      rotateActive: {
        transform: 'rotate(180deg)',
      },
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: genericS4DThemeColors.tertiaryFont,
          transform: 'translate(16px, 12px) scale(1)',
        },
        shrink: {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: '#fff !important',
            fontSize: '1.2rem',
          },
          '&.Mui-completed': {
            color: 'rgb(220,220,220) !important',
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: genericS4DThemeColors.tertiaryFont,
          },
        },
        icon: {
          color: `${genericS4DThemeColors.tertiaryFont} !important`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.grey[200]}`,
        },
        sizeSmall: {
          color: '#17191B',
        },
        sizeMedium: {
          color: '#17191B',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
        input: {
          padding: '12px 16px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          input: {
            '&::placeholder': {
              color: colors.grey[500],
              opacity: 1,
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: colors.grey[500],
            opacity: 1,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '10px 16px',
          boxShadow: 'none',
        },
        sizeMedium: {
          fontWeight: 500,
        },
        sizeSmall: {
          padding: '6px 12px',
        },
        sizeLarge: {
          padding: `${sizing.spacingBase * 2}px ${sizing.spacingBase * 3}px`,
        },
      },
      variants: [
        {
          props: { variant: 'iconButton' },
          style: {
            minWidth: `${sizing.spacingBase * 6}px`,
            padding: `${sizing.spacingBase}px`,
            borderRadius: '2px',
            boxShadow: 'none',
            color: colors.grey[800],
            background: colors.grey[200],
            ':disabled': {
              background: colors.grey[100],
            },
          },
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          color: '#fff',
          height: '4px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
        textColorSecondary: '#fff',
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          borderRadius: 4,
          border: `1px solid ${colors.yellow[600]}`,
          backgroundColor: colors.yellow[100],
          color: '#17191B',
          '.MuiSvgIcon-root': {
            color: colors.yellow[800],
          },
        },
        standardError: {
          borderRadius: 4,
          border: `1px solid ${colors.red[500]}`,
          backgroundColor: colors.red[100],
          color: '#17191B',
          '.MuiSvgIcon-root': {
            color: colors.red[900],
          },
        },
        standardInfo: {
          borderRadius: 4,
          border: `1px solid ${colors.blue[500]}`,
          backgroundColor: colors.blue[100],
          color: '#17191B',
          '.MuiSvgIcon-root': {
            color: colors.blue[800],
          },
        },
        standardSuccess: {
          borderRadius: 4,
          border: `1px solid ${colors.green[500]}`,
          backgroundColor: colors.green[100],
          color: '#17191B',
          '.MuiSvgIcon-root': {
            color: colors.green[800],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          color: colors.grey[500],
        },
        colorPrimary: {
          color: 'black',
        },
      },
    },
  },
});
